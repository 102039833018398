import Error from 'components/error';
import withI18n from 'hocs/with-i18n';
import withAdminStore from 'hocs/with-admin-store';
import withParticipantLayout from 'hocs/with-participant-layout';
import Container from 'containers/hub-page/views';
import withParticipantAccess from 'hocs/with-participant-access';
import withBasicAuth from 'hocs/with-basic-auth';
import withVisitsLogging from 'hocs/with-visits-logging';
import withCSPHeader from 'hocs/with-csp-header';

const App = ({ isUnAuthorized }) => {
  if (isUnAuthorized) {
    return <Error statusCode="404" />;
  }

  return <Container />;
};

App.getInitialProps = async (ctx) => {
  const isUnAuthorized =
    ctx.reduxStore.getState().hubPageRevisions.fetchError === 'Not Found';

  return { isUnAuthorized };
};

const locales = ['common', 'navigation', 'admin-layout', 'editor'];
const PageWithVisitsLogging = withVisitsLogging(App);
const PageWithBasicAuth = withBasicAuth(PageWithVisitsLogging);
const PageWithLayoutAndI18n = withI18n(
  withParticipantLayout(PageWithBasicAuth),
  locales,
);
const PageWithCSP = withCSPHeader(PageWithLayoutAndI18n);
const PageWithParticipantAccess = withParticipantAccess(PageWithCSP);
const PageWithRedux = withAdminStore(PageWithParticipantAccess);

export default PageWithRedux;
