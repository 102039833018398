import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'i18n'
import css from 'styled-jsx/css'
import ErrorIllustration from 'static/assets/images/common/error.png'

const { className, styles } = css.resolve`
  .message {
    height: calc(100vh - 112px);
  }
`

const Error = ({ t, statusCode }) => {
  const HelpfulLinks = () => {
    return (
      <div className='ehq-mt-10'>
        <p>{t('common:common.helpfulLinksTitle')}</p>
        <div className='ehq-flex ehq-justify-between ehq-w-64 ehq-my-0 ehq-mx-auto'>
          <a href='/admin/projects'>{t('common:common.projects')}</a>
          <a href='/admin/sites/editors/home-page'>{t('common:common.appearance')}</a>
          <a href='/admin/reporting/summary'>{t('common:common.reporting')}</a>
        </div>
      </div>
    )
  }

  const errorNode = ({ errCode, title, subTitle }) => {
    return (
      <div className={`${className} message ehq-flex ehq-items-center ehq-justify-center ehq-m-6 ehq-bg-white ehq-rounded ehq-flex-col`}>
        <img src={ErrorIllustration} alt={t(`common:common.${title}`)} height='300' />
        <div className='ehq-mt-6 ehq-max-w-md ehq-text-center'>
          <p className={`${className} ehq-text-2xl ehq-font-semibold ehq-leading-loose ehq-text-black`}>{t(`common:common.${errCode}`)}</p>
          <p className='ehq-text-base ehq-font-semibold'>{t(`common:common.${title}`)}</p>
          <p className='ehq-text-sm'>{t(`common:common.${subTitle}`)}</p>
          <HelpfulLinks />
        </div>
        {styles}
      </div>
    )
  }

  return statusCode === '403' ? errorNode({ errCode: 'accessDeniedErrorMessage', title: 'accessDeniedErrorMessageTitle', subTitle: 'accessDeniedErrorMessageSubTitle' })
    : errorNode({ errCode: 'missingUrlErrorMessage', title: 'missingUrlErrorMessageTitle', subTitle: 'missingUrlErrorMessageSubTitle' })
}

Error.propTypes = {
  statusCode: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  t: PropTypes.func.isRequired
}

export default withTranslation(['common'])(Error)
